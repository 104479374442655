import React from 'react';
import { Card, HStack } from '@chakra-ui/react';
import {
  color,
  ETextType,
  NewText,
  radius,
  spacingScale,
} from '@rekosistem/web-components';
import { IProgressCardProps } from './progress-card.props';
import { ProgressBar } from '../ProgressBar';

export const ProgressCard: React.FC<IProgressCardProps> = props => {
  const {
    borderRadius = radius.md,
    borderColor = color.border.tertiary,
    borderWidth = '1px',
    p = spacingScale.xL,
    gap = '0.75rem',
    progressBarProps,
    credit = 0,
    target = 0,
    collectedText = '',
    targetText = '',
    ...rest
  } = props;
  return (
    <Card
      {...rest}
      borderRadius={borderRadius}
      borderColor={borderColor}
      p={p}
      gap={gap}
      borderWidth={borderWidth}
    >
      <HStack gap={spacingScale.mD}>
        <NewText
          text={`${credit} ${collectedText}`}
          type={ETextType.LHeadingBold}
          fontSize={'60px'}
          lineHeight={'78px'}
        />
        <NewText
          text={`from ${target} ${targetText}`}
          type={ETextType.MParagraphSemiBold}
          fontSize={'16px'}
          color={color.text.secondary}
          alignSelf={'flex-end'}
          pb={spacingScale.lG}
        />
      </HStack>
      <ProgressBar {...progressBarProps} />
    </Card>
  );
};
