import {
  IMultiLineChartProps,
  MultiLineOptionsType,
} from './multi-line-chart.props';

const options = (props: IMultiLineChartProps): MultiLineOptionsType => ({
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return ' ' + context.parsed.y + ' ' + props.units;
        },
      },
    },
  },
  scales: {
    x: {
      offset: true,
      title: {
        display: true,
        text: props.xTitle,
        color: props?.xTitleColor ?? '#313131',
        font: {
          weight: 'bold',
        },
      },
      ticks: {
        color: props?.xParamColor ?? undefined,
      },
      grid: {
        color: props?.gridColor ?? undefined,
      },
    },
    y: {
      title: {
        display: true,
        text: props.yTitle,
        color: props?.yTitleColor ?? '#313131',
        font: {
          weight: 'bold',
        },
      },
      ticks: {
        color: props?.yParamColor ?? undefined,
      },
      grid: {
        color: props?.gridColor ?? undefined,
      },
    },
  },
});

export default { options };
