import { DateSettingsType } from 'app/modules/profile/slice/types';
import { NULL_DATE } from 'config/index';
import { formatDate, formatDMY } from './date';
import {
  DashboardTabType,
  IEPRBranchPeformanceType,
  IEPRWasteChartType,
  IEPRWasteType,
} from 'types/Dashboard';
import { isEmptyList } from './list';
import { ALL_REGION } from 'app/pages/Dashboard/constants';
import { ILinesProps } from 'app/components/MultiLineChart/multi-line-chart.props';
import { compareAsc, format, isValid, parse } from 'date-fns';
import { isEmptyString } from './check-string';

export const convertGetEPRDataParameter = (
  dateSettings: DateSettingsType,
  projectNames?: string,
  regionNames?: string,
) => {
  const data = {
    startDate:
      dateSettings.type === 'all-time'
        ? NULL_DATE
        : formatDate(dateSettings.startDate, 'yyyy-MM-dd'),
    endDate: formatDate(dateSettings.endDate, 'yyyy-MM-dd'),
    typeDate: dateSettings.type,
    projectNames:
      projectNames && !isEmptyString(projectNames) ? projectNames : undefined,
    regionNames: regionNames !== ALL_REGION ? regionNames : undefined,
  };

  return data;
};

export const chartDataCountConverter = (data: IEPRWasteChartType[]) => {
  const chartData: number[] = [];
  if (data) {
    data.map(item => {
      chartData.push(item?.count);
    });
  }
  return chartData;
};

export const generateRandomColor = (): string => {
  let color: string;

  do {
    color =
      '#' +
      Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, '0');
  } while (color === '#FFFFFF' || color === '#000000');

  return color;
};

export const getEPRChartDataWithRandomColor = (
  data: IEPRWasteChartType[] | IEPRBranchPeformanceType[],
) => {
  if (isEmptyList(data) || !data) return [];

  return data?.map(item => ({
    ...item,
    color: generateRandomColor(),
  }));
};

export const loadDynamicWasteTypeTab = (
  tab: DashboardTabType[],
  wasteType: IEPRWasteType[],
) => {
  const START_INDEX = 2;
  const INDEX_TO_REMOVE = 0;

  tab?.splice(
    START_INDEX,
    INDEX_TO_REMOVE,
    ...wasteType.map(item => ({
      key: `growth-${item?.id}`,
      name: item?.name,
    })),
  );
};

export const multiLineConverter = (
  data: IEPRBranchPeformanceType[],
): ILinesProps[] => {
  const lines = data?.map(item => ({
    lineLabel: item.wasteName,
    dates: item?.data?.map(point => formatDMY(point?.date)),
    weights: item.data.map(point => point?.weight),
    color: item?.color,
  }));

  return lines;
};

export const sortDatetMultipleLineChart = (lines: ILinesProps[]) => {
  const labels = Array.from(new Set(lines.flatMap(line => line.dates)))
    .map(date => {
      // Parse the date with the correct format
      const parsedDate = parse(date, 'dd/MM/yyyy', new Date());
      return isValid(parsedDate) ? parsedDate : null; // Ensure date is valid
    })
    .filter((date): date is Date => date !== null) // Remove any null values (TypeScript type guard)
    .sort(compareAsc) // Sort dates in ascending order
    .map(date => format(date, 'dd/MM/yyyy')); // Format back to 'dd/MM/yyyy' strings

  return labels;
};
