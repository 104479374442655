import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { percentage } from '../../../utils/dashboard-helper';

export interface IPieChartProps {
  label: string;
  title: string;
  xParam: any[];
  yParam: any[];
  color: any[];
  withoutBottomTitle?: boolean;
  withoutDataLabels?: boolean;
}

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

defaults.font.family = 'Montserrat';

export const PieChart = (props: IPieChartProps) => {
  const optionsPie = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: props?.withoutBottomTitle ? false : true,
        text: 'Waste precentage',
        position: 'bottom' as const,
      },
      datalabels: {
        display: props?.withoutDataLabels ? false : true,
        color: '#000000',
        formatter: function (value, context) {
          if (percentage(value, props.xParam) > 10) {
            return (
              context.chart.data.labels[context.dataIndex] +
              '\n' +
              percentage(value, props.xParam).toFixed(2) +
              '%'
            );
          } else {
            return '';
          }
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return (
              ' ' + percentage(context.parsed, props.xParam).toFixed(2) + '%'
            );
          },
        },
      },
    },
  };

  const data = {
    labels: props.yParam,
    datasets: [
      {
        label: props.label,
        data: props.xParam,
        backgroundColor: props.color,
        borderWidth: 0,
      },
    ],
  };

  return <Pie data={data} options={optionsPie} />;
};
