import { HEADERS } from 'apisauce';
import { GeneralApiProblem } from './api-problem';
import { Pagination, AccountListType, ListReportType } from 'types';

/**
 * Storage Key
 */
export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const USER_DETAIL_KEY = 'userDetail';
export const ROLE_KEY = 'role';
export const WASTE_MANAGEMENT_TYPE_KEY = 'wasteManagementType';
export const HEADER_USER_ID_KEY = 'headerUserId';
export const HEADER_USER_CODE_KEY = 'headerUserCode';
export const USER_MP_DETAIL_KEY = 'userDetailMp';

/**
 * API Definition
 */
const AUTH_PORT = process.env.REACT_APP_AUTH;
const CORE_PORT = process.env.REACT_APP_CORE;

// Auth & User
export const LOGIN = AUTH_PORT + '/auth/login';
export const REFRESH_TOKEN = AUTH_PORT + '/auth/refresh-token';
export const REQUEST_RESET_PASSWORD =
  AUTH_PORT + '/auth/request-reset-password';
export const RESET_PASSWORD = AUTH_PORT + '/auth/reset-password';
export const CHANGE_PASSWORD = AUTH_PORT + '/user/change-password';
export const CHECK_PASSWORD = AUTH_PORT + '/user/check-password';
export const USER_DETAIL = AUTH_PORT + '/user/detail';
export const WASTE_MANAGEMENT = CORE_PORT + '/waste-management-type-customer';
export const WASTE_TYPE = CORE_PORT + '/waste-type';
export const CLIENT_LIST = AUTH_PORT + '/user/client-list';

// Report
export const REPORT_LIST = CORE_PORT + '/report/list';
export const REPORT_DOWNLOAD = CORE_PORT + '/report/download';

// Dashboard Business (RWM & ZWM)
export const BUSINESS_OVERVIEW = CORE_PORT + '/transaction/summary-repickup';
export const WEIGHT_PER_PICKUP = CORE_PORT + '/transaction/weight-per-pickup';
export const BUSINESS_WEIGHT_PER_TYPE =
  CORE_PORT + '/transaction/weight-per-type-repickup';
export const BUSINESS_EXCEL_WEIGHT_PER_PICK_UP =
  CORE_PORT + '/transaction/excel-weight-per-pickup';
export const BUSINESS_EXCEL_WEIGHT_PER_TYPE =
  CORE_PORT + '/transaction/excel-weight-per-type-repickup';
export const BUSINESS_EXCEL_WASTE_JOURNEY =
  CORE_PORT + '/transaction/excel-weight-by-journey-repickup';
export const BUSINESS_CARBON_FOOTPRINT =
  CORE_PORT + '/transaction/carbon-footprint-repickup';
export const BUSINESS_WASTE_JOURNEY =
  CORE_PORT + '/transaction/weight-by-journey-repickup';

// Dashboard Rebox Brandbox
export const REBOX_BRANDBOX_OVERVIEW =
  CORE_PORT + '/transaction/summary-brandbox';
export const REBOX_BRANDBOX_TOTAL_WASTE =
  CORE_PORT + '/transaction/waste-brandbox';
export const REBOX_BRANDBOX_TOTAL_TRANSACTION =
  CORE_PORT + '/transaction/transaction-brandbox';
export const REBOX_BRANDBOX_WEIGHT_PER_TYPE =
  CORE_PORT + '/transaction/weight-per-type-brandbox';
export const REBOX_BRANDBOX_TOTAL_USER =
  CORE_PORT + '/transaction/user-brandbox';
export const REBOX_BRANDBOX_TOTAL_WASTE_EXCEL =
  CORE_PORT + '/transaction/excel-waste-brandbox';
export const REBOX_BRANDBOX_WEIGHT_PER_TYPE_EXCEL =
  CORE_PORT + '/transaction/excel-weight-per-type-brandbox';
export const REBOX_BRANDBOX_TOTAL_TRANSACTION_EXCEL =
  CORE_PORT + '/transaction/excel-transaction-brandbox';
export const REBOX_BRANDBOX_TOTAL_USER_EXCEL =
  CORE_PORT + '/transaction/excel-user-brandbox';

// Dashboard Drop-In
export const DROP_IN_OVERVIEW = CORE_PORT + '/transaction/summary-dropin';
export const DROP_IN_CARBON_FOOTPRINT =
  CORE_PORT + '/transaction/carbon-footprint-dropin';
export const DROP_IN_WEIGHT = CORE_PORT + '/transaction/weight-dropin';
export const DROP_IN_TOTAL_DROP = CORE_PORT + '/transaction/waste-dropin';
export const DROP_IN_TOTAL_USER = CORE_PORT + '/transaction/user-dropin';
export const DROP_IN_WEIGHT_PER_TYPE =
  CORE_PORT + '/transaction/weight-per-type-dropin';
export const DROP_IN_WASTE_JOURNEY =
  CORE_PORT + '/transaction/weight-by-journey-dropin';
export const DROP_IN_WEIGHT_EXCEL =
  CORE_PORT + '/transaction/excel-weight-dropin';
export const DROP_IN_WASTE_EXCEL =
  CORE_PORT + '/transaction/excel-waste-dropin';
export const DROP_IN_USER_EXCEL = CORE_PORT + '/transaction/excel-user-dropin';
export const DROP_IN_WEIGHT_PER_TYPE_EXCEL =
  CORE_PORT + '/transaction/excel-weight-per-type-dropin';
export const DROP_IN_WASTE_JOURNEY_EXCEL =
  CORE_PORT + '/transaction/excel-weight-by-journey-dropin';

// Account Manager
export const ACCOUNT_MANAGER_OVERVIEW =
  AUTH_PORT + '/user/account-manager-overview';
export const ACCOUNT_LIST = AUTH_PORT + '/user/account-manager-list';
export const SHARE_ACCESS = AUTH_PORT + '/user/new';
export const DELETE_ACCESS = AUTH_PORT + '/user/';
export const BRANCH_LOCATION_LIST = AUTH_PORT + '/user/branch-location';
export const GET_ACCOUNT_DETAIL = AUTH_PORT + '/user/';
export const EDIT_ACCESS_DETAIL = AUTH_PORT + '/user';

// Leaderboard
export const LEADERBOARD_LIST =
  CORE_PORT + '/drop-in/leaderboard/drop-stations';
export const LEADERBOARD_EXCEL =
  CORE_PORT + '/drop-in/leaderboard/drop-stations/excel';

// Bought-In
export const BOUGHT_IN_SUMMARY = CORE_PORT + '/repickup/summary-bought-in';
export const BOUGHT_IN_TRANSACTION = CORE_PORT + '/repickup/bought-in';
export const BOUGHT_IN_EXCEL = CORE_PORT + '/repickup/bought-in/excel';

// Plastic Credit Dashboard
const PLASTIC_CREDIT = '/plastic-credit';
export const PLASTIC_CREDIT_HEADER = CORE_PORT + PLASTIC_CREDIT;
export const PLASTIC_CREDIT_SUMMARY = CORE_PORT + PLASTIC_CREDIT + '/summary';
export const PLASTIC_CREDIT_CARBON_FOOTPRINT =
  CORE_PORT + PLASTIC_CREDIT + '/carbon-footprint';
export const PLASTIC_CREDIT_COLLECTION_CITIES =
  CORE_PORT + PLASTIC_CREDIT + '/collection-cities';
export const PLASTIC_CREDIT_COLLECTION_HISTORY =
  CORE_PORT + PLASTIC_CREDIT + '/collection-history';
export const PLASTIC_CREDIT_COLLECTION_HISTORY_DOWNLOAD =
  CORE_PORT + PLASTIC_CREDIT + '/collection-history/download';
export const PLASTIC_CREDIT_DESTINATION_CITIES =
  CORE_PORT + PLASTIC_CREDIT + '/destination-cities';
export const PLASTIC_CREDIT_REGION = CORE_PORT + PLASTIC_CREDIT + '/region';
export const PLASTIC_CREDIT_TOTAL_WASTE =
  CORE_PORT + PLASTIC_CREDIT + '/total-waste';
export const PLASTIC_CREDIT_WASTE_TYPE =
  CORE_PORT + PLASTIC_CREDIT + '/waste-type';

// EPR Dashboard
const EPR = '/epr';
export const EPR_WASTE_TYPE = CORE_PORT + EPR + '/waste-type';
export const EPR_SUMMARY = CORE_PORT + EPR + '/summary';
export const EPR_REGION = CORE_PORT + EPR + '/region';
export const EPR_WASTE_TYPE_PEFORMANCE = EPR_WASTE_TYPE + '/performance';
export const EPR_WASTE_TYPE_SUMMARY = EPR_WASTE_TYPE + '/summary';
export const EPR_WASTE_COLLECTION_REGION =
  CORE_PORT + EPR + '/waste-collection/region';
export const EPR_WASTE_COLLECTION_SOURCE =
  CORE_PORT + EPR + '/waste-collection/source';
export const EPR_WASTE_COLLECTION_WASTE_TYPES =
  CORE_PORT + EPR + '/waste-collection/waste-types';
export const EPR_IMPACT_INSIGHTS = CORE_PORT + EPR + '/impact-insight';
export const EPR_IMPACT_INSIGHTS_CONSUMER_DEMOGRAPHIC =
  CORE_PORT + EPR + '/impact-insight/consumer-demographic';
export const EPR_CARBON_FOOTPRINT = CORE_PORT + EPR + '/carbon-footprint';

export interface GeneralDataResponse {
  data: any;
  errors: any;
  message: string | any;
  status: boolean | any;
}

export interface ListReportResponse extends GeneralDataResponse {
  data: {
    pagination: Pagination;
    data: ListReportType[];
  };
}

export interface AccountListResponse extends GeneralDataResponse {
  data: {
    pagination: Pagination;
    data: AccountListType[];
  };
}

export enum ApiResponseKind {
  OK = 'ok',
}

export enum ApiMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export const MULTIPART_HEADER = {
  'Content-Type': 'multipart/form-data',
};

export enum ResponseType {
  BLOB = 'blob',
}

export type GeneralApiResult =
  | {
      kind: ApiResponseKind.OK;
      data: GeneralDataResponse;
      headers: HEADERS | undefined;
    }
  | GeneralApiProblem;
