export const DOWNLOAD: string = 'Download';
export const DOWNLOAD_MODAL_TITLE: string = 'Download Format';
export const DOWNLOAD_MODAL_POSITIVE_BUTTON: string = 'Download';
export const DOWNLOAD_MODAL_NEGATIVE_BUTTON: string = 'Cancel';
export const TOOLTIP_REFERENCE: string = 'Source:';
export const DATA_NOT_FOUND_TITLE: string = 'Currently, no data is available';
export const DATA_NOT_FOUND_DESC: string =
  'Data will display here once it becomes available.';
export const PERCENTAGE_MULTIPLIER: number = 100;

export const ALL_REGION: string = 'All Region';
