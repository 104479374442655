export const formatCurrency = (
  value: string | undefined | null,
  isRoundUp?: boolean,
  minFractionDigits?: number,
  maxFractionDigits?: number,
) => {
  if (!value) return 0;
  let n = Number(value);
  if (isRoundUp) n = Math.round(n);
  const formattedNumber: string = n.toLocaleString('en-US', {
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  });

  return formattedNumber.trim();
};

export const formatFloatNumber = (data: number) => {
  const options = Number.isInteger(data)
    ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
    : { minimumFractionDigits: 1, maximumFractionDigits: 1 };

  return new Intl.NumberFormat('de-DE', options).format(data);
};

export function currencyFormatter(
  amount = 0,
  prefix?: string,
  decimalPlace?: number,
) {
  const formatAmount = Number(amount)
    .toFixed(decimalPlace ?? 0) // Number of decimal places to fix the amount to
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Replace every group of three digits with a period separator

  return `${prefix ? prefix + ' ' : ''}${formatAmount}`;
}
