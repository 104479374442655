import React from 'react';
import {
  CategoryScale,
  Chart as ChartJS,
  defaults,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { NotFound } from '../NotFound';
import {
  DATA_NOT_FOUND_DESC,
  DATA_NOT_FOUND_TITLE,
} from '../../pages/Dashboard/constants';

export interface ILineChartProps {
  id?: string;
  label: string;
  xParam: any[];
  xTitle: string;
  yParam: any[];
  yTitle: string;
  units?: string;
  chartStyle?: React.CSSProperties;
  xTitleColor?: string;
  yTitleColor?: string;
  borderColor?: string;
  xParamColor?: string;
  yParamColor?: string;
  gridColor?: string;
  withEmptyState?: boolean;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

defaults.font.family = 'Montserrat';

export const LineChart = (props: ILineChartProps) => {
  const { withEmptyState = true } = props;
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return ' ' + context.parsed.y + ' ' + props.units;
          },
        },
      },
    },
    scales: {
      x: {
        offset: true,
        title: {
          display: true,
          text: props.xTitle,
          color: props?.xTitleColor ?? '#313131',
          font: {
            weight: 'bold',
          },
        },
        ticks: {
          color: props?.xParamColor ?? undefined,
        },
        grid: {
          color: props?.gridColor ?? undefined,
        },
      },
      y: {
        title: {
          display: true,
          text: props.yTitle,
          color: props?.yTitleColor ?? '#313131',
          font: {
            weight: 'bold',
          },
        },
        ticks: {
          color: props?.yParamColor ?? undefined,
        },
        grid: {
          color: props?.gridColor ?? undefined,
        },
      },
    },
  };

  const labels = props.xParam;

  const data = {
    labels,
    datasets: [
      {
        label: props.label,
        data: props.yParam,
        borderColor: props?.borderColor ?? '#004ED0',
        backgroundColor: '#FFFFFF',
      },
    ],
  };

  return (
    <div>
      {props.label.length && props.xParam.length ? (
        <div id={props.id ?? ''} className="mb-4" style={props?.chartStyle}>
          <Line data={data} options={options} />
        </div>
      ) : withEmptyState ? (
        <NotFound title={DATA_NOT_FOUND_TITLE} desc={DATA_NOT_FOUND_DESC} />
      ) : null}
    </div>
  );
};
