import { StackProps } from '@chakra-ui/react';

export interface ILegendsProps {
  type: ELegendsType;
  label: string;
  color?: string;
  percentage: string;
  units: string;
  value: number | string;
  number?: number;
  style?: StackProps;
}

export enum ELegendsType {
  Bar = 'Bar',
  Circle = 'Circle',
  Box = 'Box',
}
