import React from 'react';
import { ELegendsType, ILegendsProps } from './legends.props';
import { HStack, VStack } from '@chakra-ui/react';
import styles from './legends.style';
import {
  NewText,
  color as Colors,
  spacingScale,
} from '@rekosistem/web-components';

export const Legends: React.FC<ILegendsProps> = props => {
  const {
    type,
    number = 1,
    color = Colors.celurenBlue[500],
    label = '',
    value = 0,
    units = '',
    percentage = '0%',
    style,
  } = props;

  if (!type) return <></>;

  //   CIRCLE TYPE
  if (type === ELegendsType.Circle)
    return (
      <HStack
        {...styles.stack.container}
        {...styles.stack.circleContainer}
        {...style}
      >
        <HStack {...styles.stack.leftCircle}>
          <NewText {...styles.text.numbers} text={`${number}`} />
          <HStack {...styles.stack.circle} bgColor={color} />
          <NewText {...styles.text.label} text={label} />
        </HStack>
        <HStack {...styles.stack.rightCircle}>
          <HStack gap={spacingScale['2xS']}>
            <NewText {...styles.text.unitsValue} text={`${value} `} />
            <NewText {...styles.text.units} text={units} />
          </HStack>
          <NewText {...styles.text.value} text={'•'} />
          <HStack gap={spacingScale['2xS']}>
            <NewText {...styles.text.value} text={`${percentage}`} />
            <NewText {...styles.text.percentage} text={`%`} />
          </HStack>
        </HStack>
      </HStack>
    );

  // BAR TYPE
  if (type === ELegendsType.Bar)
    return (
      <HStack
        {...styles.stack.container}
        {...styles.stack.barContainer}
        {...style}
      >
        <HStack {...styles.stack.spacingBar} {...styles.stack.leftBar}>
          <HStack {...styles.stack.bar} bgColor={color} />
          <NewText
            {...styles.text.label}
            {...styles.text.labelBar}
            text={label}
          />
        </HStack>
        <HStack {...styles.stack.spacingBar}>
          <NewText {...styles.text.value} text={`${percentage}%`} />
          <NewText {...styles.text.value} text={'•'} />
          <NewText {...styles.text.value} text={`${value} ${units}`} />
        </HStack>
      </HStack>
    );

  // BOX TYPE
  return (
    <HStack {...styles.stack.boxContainer} {...style}>
      <HStack {...styles.stack.box} bgColor={color} />
      <VStack alignItems={'flex-start'}>
        <NewText {...styles.text.boxLabel} text={label} />
        <HStack>
          <NewText {...styles.text.value} text={`${percentage}`} />
          <NewText {...styles.text.percentage} text={` % `} />
          <NewText {...styles.text.value} text={'•'} />
          <NewText {...styles.text.value} text={`${value} `} />
          <NewText {...styles.text.units} text={units} />
        </HStack>
      </VStack>
    </HStack>
  );
};
