import html2canvas from 'html2canvas';
import { DateSettingsType } from 'app/modules/profile/slice/types';
import { defaultSelectedWasteType } from 'app/modules/dashboard';
import {
  WasteManagementResponseType,
  WasteManagementType,
  BusinessWeightPerPickupType,
  BusinessWeightPerTypeType,
  DropInTotalDropType,
  DropInTotalUserType,
  GetOverviewReboxBrandboxType,
  GetOverviewType,
  GetWeightPerPickupType,
  LinkType,
  NodeType,
  ReboxBrandboxTotalTransactionType,
  ReboxBrandboxTotalWasteType,
  SankeyType,
  WasteJourneyType,
  SubAccountType,
  IChartData,
  IPlasticCreditTotalWasteType,
  IEPRWasteChartType,
} from 'types';
import {
  BUSINESS_TYPE,
  DASHBOARD_BUSINESS,
  DASHBOARD_DROP_IN,
  DASHBOARD_REBOX_BRANDBOX,
  DASHBOARD_PLASTIC_CREDIT,
  DASHBOARD_EPR,
  DATE_NULL,
  DATE_OPTION,
  DATE_OPTION_MONTH,
  DATE_OPTION_WEEK,
  downloadOptionXLS,
  DROP_IN_TYPE,
  EPR_TYPE,
  PLASTIC_CREDIT_TYPE,
  DATE_OPTION_ALL,
} from 'config';
import { OptionType } from 'app/components';
import { CSV_TYPE, XLSX_TYPE, Y_PARAM_CONVERTER_TYPE } from '../constants';
import { formatDashboardPostDate, formatDMY, formatMonthYear } from './date';
import { isEmptyString, replaceChar } from './check-string';

const FIRST_ARRAY: number = 0;

export function convertWasteManagementType(
  wasteManagement: WasteManagementType,
) {
  if (wasteManagement.type === BUSINESS_TYPE) {
    return DASHBOARD_BUSINESS;
  } else if (wasteManagement.type === DROP_IN_TYPE) {
    return DASHBOARD_DROP_IN;
  } else if (wasteManagement.type === PLASTIC_CREDIT_TYPE) {
    return DASHBOARD_PLASTIC_CREDIT;
  } else if (wasteManagement.type === EPR_TYPE) {
    return DASHBOARD_EPR;
  } else {
    return DASHBOARD_REBOX_BRANDBOX;
  }
}

export const convert = (
  data: WasteManagementResponseType[],
): WasteManagementType[] =>
  data.map(item => ({
    wasteManagementTypeId: item.id,
    wasteManagementTypeName: item.name,
    dropPointId: [],
    type: item.type,
    subAccounts: item.locations,
  }));

export const downloadSection = async (id: string) => {
  const element = document.getElementById(id);
  const link = document.createElement('a');
  let canvas;
  if (element) {
    canvas = await html2canvas(element);
  }

  link.href = canvas.toDataURL('image/jpg');
  link.download = id + '.jpg';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const convertGetDataParameter = (
  dateSettings: DateSettingsType,
  selectedWasteManagementType: WasteManagementType,
  selectedSubAccount: SubAccountType,
  wasteType?: OptionType[],
  selectedWasteType?: OptionType,
  fileType?: string,
  dropPointId?: number[],
) => {
  const data: GetWeightPerPickupType = {
    startDate:
      dateSettings.type === 'all-time'
        ? DATE_NULL
        : formatDashboardPostDate(dateSettings.startDate),
    endDate: formatDashboardPostDate(dateSettings.endDate),
    typeDate: dateSettings.type,
    wasteManagementTypeId: selectedWasteManagementType.wasteManagementTypeId,
    subAccounts: selectedSubAccount.name.split(','),
  };

  if (wasteType && selectedWasteType) {
    data.wasteType = [];
    if (selectedWasteType === defaultSelectedWasteType)
      wasteType.map(item => {
        if (item !== defaultSelectedWasteType) {
          data.wasteType?.push(item.key);
        }
      });
    else data.wasteType?.push(selectedWasteType.key);
  }

  if (fileType) data.fileType = fileType;

  if (dropPointId) data.dropPointId = dropPointId;

  return data;
};

export const percentage = (value: number, data: number[]) => {
  let sum = 0;
  data.map(item => {
    sum = sum + item;
  });
  return (value / sum) * 100;
};

export const xParamConverter = (
  data:
    | BusinessWeightPerPickupType[]
    | DropInTotalDropType[]
    | DropInTotalUserType[]
    | ReboxBrandboxTotalTransactionType[]
    | ReboxBrandboxTotalWasteType[]
    | IPlasticCreditTotalWasteType[]
    | IEPRWasteChartType[],
  dateSettings: DateSettingsType,
) => {
  const xParam: string[] = [];
  if (data) {
    data.map(item => {
      if (dateSettings.type === DATE_OPTION[DATE_OPTION_MONTH].key) {
        xParam.push(formatMonthYear(item.xParam));
      } else if (dateSettings.type === DATE_OPTION[DATE_OPTION_WEEK].key) {
        xParam.push('Week ' + item.xParam);
      } else {
        xParam.push(formatDMY(item.xParam));
      }
    });
  }
  return xParam;
};

export const yParamConverter = (
  data:
    | BusinessWeightPerPickupType[]
    | DropInTotalDropType[]
    | DropInTotalUserType[]
    | ReboxBrandboxTotalTransactionType[]
    | ReboxBrandboxTotalWasteType[]
    | IPlasticCreditTotalWasteType[]
    | IEPRWasteChartType[],

  type: Y_PARAM_CONVERTER_TYPE,
) => {
  const yParam: number[] = [];
  if (data) {
    data.map(item => {
      if (type === Y_PARAM_CONVERTER_TYPE.Weight) yParam.push(item.totalWeight);
      if (type === Y_PARAM_CONVERTER_TYPE.Drop) yParam.push(item.totalDropin);
      if (type === Y_PARAM_CONVERTER_TYPE.User) yParam.push(item.totalUser);
      if (type === Y_PARAM_CONVERTER_TYPE.Transaction)
        yParam.push(item.totalTransaction);
      if (type === Y_PARAM_CONVERTER_TYPE.Waste) yParam.push(item.totalWaste);
    });
  }
  return yParam;
};

export const chartDataConverter = (
  data: BusinessWeightPerTypeType[] | IChartData[],
) => {
  const chartData: number[] = [];
  if (data) {
    data.map(item => {
      chartData.push(item.weight);
    });
  }
  return chartData;
};

export const chartLabelConverter = (data: BusinessWeightPerTypeType[]) => {
  const chartLabel: string[] = [];
  if (data) {
    data.map(item => {
      chartLabel.push(item.type);
    });
  }
  return chartLabel;
};

export const chartLabelCityConverter = (data: IChartData[]) => {
  const chartLabel: string[] = [];
  if (data) {
    data.map(item => {
      chartLabel.push(item.city);
    });
  }
  return chartLabel;
};

export const chartColorConverter = (
  data: BusinessWeightPerTypeType[] | IChartData[],
) => {
  const chartColor: string[] = [];
  if (data) {
    data.map(item => {
      chartColor.push('#' + replaceChar(item.color, '#', ''));
    });
  }
  return chartColor;
};

export const convertDataOverview = (
  dateSettings: DateSettingsType,
  selectedWasteManagementType: WasteManagementType,
  selectedSubAccount: SubAccountType,
  // drop point id intentionally made into separate
  // param form selected waste management type to make function reusable
  dropPointId?: number[],
) => {
  const data: GetOverviewType = {
    startDate:
      dateSettings.type === 'all-time'
        ? DATE_NULL
        : formatDashboardPostDate(dateSettings.startDate),
    endDate: formatDashboardPostDate(dateSettings.endDate),
    typeDate: dateSettings.type,
    wasteManagementTypeId: selectedWasteManagementType.wasteManagementTypeId,
    subAccounts: selectedSubAccount.name.split(','),
  };
  if (dropPointId) data.dropPointId = dropPointId;

  return data;
};

export const convertDataOverviewReboxBrandbox = (
  dateSettings: DateSettingsType,
  selectedWasteManagementType: WasteManagementType,
  selectedSubAccount: SubAccountType,
  wasteType?: OptionType[],
  selectedWasteType?: OptionType,
) => {
  const data: GetOverviewReboxBrandboxType = {
    startDate:
      dateSettings.type === 'all-time'
        ? DATE_NULL
        : formatDashboardPostDate(dateSettings.startDate),
    endDate: formatDashboardPostDate(dateSettings.endDate),
    typeDate: dateSettings.type,
    wasteManagementTypeId:
      selectedWasteManagementType.wasteManagementTypeId[FIRST_ARRAY],
  };

  data.dropPointId = selectedSubAccount?.droppointIds;

  if (wasteType && selectedWasteType) {
    data.wasteType = [];
    if (selectedWasteType === defaultSelectedWasteType) {
      wasteType.map(item => {
        if (item !== defaultSelectedWasteType) {
          data.wasteType?.push(item.key);
        }
      });
    } else {
      data.wasteType?.push(selectedWasteType.key);
    }
  }

  return data;
};

export const fileType = (data: OptionType) => {
  return data.key === downloadOptionXLS.key ? XLSX_TYPE : CSV_TYPE;
};

const removeString = (data: string, remove: string) => {
  const indexOfRemove = data.indexOf(remove);
  if (indexOfRemove !== -1) {
    return data.substring(0, indexOfRemove);
  } else {
    return data;
  }
};

export const sankeyDataConverter = (data: WasteJourneyType) => {
  const node: NodeType[] = [];
  data.sourceData.map(item => {
    node.push({
      name: item.from,
      color: '',
    });
    node.push({
      name: item.to,
      color: '',
    });
  });
  const mapped = node.map(obj => obj.name);
  const filtered = mapped.filter(
    (name, index) => mapped.indexOf(name) === index,
  );
  const newNode: NodeType[] = [];
  filtered.map(item => {
    const itemColor: string = data.sourceColor[
      data.sourceColor.findIndex(obj => obj.name === removeString(item, ' - '))
    ]?.color.replace('#', '');
    newNode.push({
      name: item,
      color: '#' + itemColor, // Make sure there is no '#' in the hex code,
      // and added it manually from frontend
    });
  });

  const link: LinkType[] = [];
  data.sourceData.map(item => {
    link.push({
      source: filtered.findIndex(obj => obj === item.from),
      target: filtered.findIndex(obj => obj === item.to),
      value: item.weight,
    });
  });
  const result: SankeyType = {
    links: link,
    nodes: newNode,
  };
  return result;
};

export const downloadFile = (url: string, name: string, type: string) => {
  if (!isEmptyString(url)) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name + '.' + type);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

export const blobToUrl = (blob: string, type: string) => {
  return URL.createObjectURL(
    new Blob([blob], {
      type: type,
    }),
  );
};

export const excelFilename = (name: string) => {
  return name + formatDMY(new Date());
};

export const arrayToString = (data: number[]) => {
  return data.join(',');
};

// This helper only used temporary, because report only available for user with re-pickup business for now
export const reportProgram = (
  isReport: boolean,
  data: WasteManagementType[],
) => {
  if (isReport) {
    return data.filter(item => item.type === BUSINESS_TYPE);
  } else {
    return data;
  }
};

// This helper only used temporary, because report only available for user with re-pickup business for now
export const isShowReport = (data: WasteManagementType[]) => {
  const filtered: WasteManagementType[] = data.filter(
    item => item.type === BUSINESS_TYPE,
  );
  return filtered.length > 0;
};

export const alias = (
  reportSubAccount: string,
  subAccounts: SubAccountType[],
): string => {
  if (isEmptyString(reportSubAccount)) return '';
  const subAccount = subAccounts.find(item => item.name === reportSubAccount);
  return subAccount?.alias || '';
};

export const isAllTimeDate = (dateSettings: DateSettingsType) => {
  return dateSettings.type === DATE_OPTION_ALL.key;
};

export async function downloadBlobFile(data: any, headers: any, type: string) {
  // Extract filename from Content-Disposition header
  let fileName = 'download';
  if (headers) {
    const contentDisposition = headers?.['content-disposition'];

    if (contentDisposition) {
      if (contentDisposition?.includes('attachment')) {
        const match = contentDisposition?.match(/filename="(.+)"/);
        if (match && match[1]) {
          fileName = match[1];
        }
      }
    }
  }
  // Create a URL for the blob
  const url = blobToUrl(data, type);

  // Download the file
  if (!isEmptyString(url)) {
    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
