import { StackProps } from '@chakra-ui/react';
import {
  color,
  ETextType,
  ITextProps,
  radius,
  spacingScale,
} from '@rekosistem/web-components';
import {
  IChartData,
  IEPRWasteChartType,
  IPlasticCreditWasteType,
} from 'types/Dashboard';
import { ELegendsType, ILegendsProps } from './legends.props';
import { formatPercentage } from 'utils/plastic-credit-dashboard-helper';
import { formatFloatNumber } from 'utils/currency';

const stack = {
  container: {
    width: '100%',
    justifyContent: 'space-between',
    py: spacingScale.mD,
  },
  circleContainer: {
    borderBottomWidth: '1px',
    borderBottomColor: color.border.tertiary,
  },
  circle: {
    bgColor: color.celurenBlue[500],
    borderRadius: radius.full,
    borderWidth: '1px',
    borderColor: color.border.secondary,
    width: '20px',
    height: '20px',
  },
  leftCircle: {
    gap: spacingScale.lG,
  },
  rightCircle: {
    gap: spacingScale.sM,
  },
  barContainer: {
    gap: spacingScale.xL,
  },
  spacingBar: {
    gap: spacingScale.mD,
  },
  leftBar: {
    display: 'flex',
    flex: 1,
  },
  bar: {
    bgColor: color.celurenBlue[500],
    borderRadius: radius.xs,
    width: '48px',
    height: '20px',
  },
  box: {
    bgColor: color.celurenBlue[500],
    borderRadius: radius.xs,
    width: '24px',
    height: '20px',
    alignSelf: 'flex-start',
  },
  boxContainer: {
    gap: spacingScale.mD,
  },
} as const satisfies Record<string, StackProps>;

const text = {
  numbers: {
    type: ETextType.MParagraphMedium,
    color: color.text.secondary,
  },
  label: {
    type: ETextType.MParagraphMedium,
  },
  labelBar: {
    width: '80%',
  },
  unitsValue: {
    type: ETextType.MParagraphBold,
  },
  value: {
    type: ETextType.MParagraphBold,
  },
  units: {
    type: ETextType.SParagraphMedium,
    color: color.text.tertiary,
  },
  percentage: {
    type: ETextType.SParagraphSemiBold,
    color: color.text.tertiary,
  },
  boxLabel: {
    type: ETextType.MParagraphMedium,
  },
} as const satisfies Record<string, ITextProps>;

export const legends = {
  bar: (item: IChartData): ILegendsProps => ({
    ...item,
    type: ELegendsType.Bar,
    label: item.city,
    value: formatFloatNumber(item?.weight) ?? '',
    units: 'kg',
    percentage: formatPercentage(item?.percentage) ?? '0',
  }),
  circle: (item, number): ILegendsProps => ({
    ...item,
    type: ELegendsType.Circle,
    label: item?.city ?? '',
    value: formatFloatNumber(item?.weight) ?? '',
    units: 'kg',
    percentage: formatPercentage(item?.percentage) ?? '0',
    number: (number ?? 0) + 1,
  }),
  box: (item: IChartData): ILegendsProps => ({
    ...item,
    type: ELegendsType.Box,
    label: item.city,
    value: formatFloatNumber(item?.weight) ?? '',
    units: 'kg',
    percentage: formatPercentage(item?.percentage) ?? '0',
  }),
} as const satisfies Record<
  string,
  (item: IChartData, number?: number) => ILegendsProps
>;

export const legendsWasteType = {
  bar: (item): ILegendsProps => ({
    ...item,
    type: ELegendsType.Bar,
    label: item.type,
    value: formatFloatNumber(item?.weight) ?? '',
    units: 'kg',
    percentage: formatPercentage(item?.percentage) ?? '0',
  }),
  circle: (item, number): ILegendsProps => ({
    ...item,
    type: ELegendsType.Circle,
    label: item?.type ?? '',
    value: formatFloatNumber(item?.weight) ?? '',
    units: 'kg',
    percentage: formatPercentage(item?.percentage) ?? '0',
    number: (number ?? 0) + 1,
  }),
  box: (item): ILegendsProps => ({
    ...item,
    type: ELegendsType.Box,
    label: item.type,
    value: formatFloatNumber(item?.weight) ?? '',
    units: 'kg',
    percentage: formatPercentage(item?.percentage) ?? '0',
  }),
} as const satisfies Record<
  string,
  (item: IPlasticCreditWasteType, number?: number) => ILegendsProps
>;

export const legendsEPRWasteType = {
  bar: (item): ILegendsProps => ({
    ...item,
    type: ELegendsType.Bar,
    label: item.type,
    value: formatFloatNumber(item?.weight) ?? '',
    units: 'kg',
    percentage: formatPercentage(item?.percentage) ?? '0',
  }),
  circle: (item, number): ILegendsProps => ({
    ...item,
    type: ELegendsType.Circle,
    label: item?.type ?? '',
    value: formatFloatNumber(item?.weight) ?? '',
    units: 'kg',
    percentage: formatPercentage(item?.percentage) ?? '0',
    number: (number ?? 0) + 1,
  }),
  box: (item): ILegendsProps => ({
    ...item,
    type: ELegendsType.Box,
    label: item.type,
    value: formatFloatNumber(item?.weight) ?? '',
    units: 'kg',
    percentage: formatPercentage(item?.percentage) ?? '0',
  }),
} as const satisfies Record<
  string,
  (item: IEPRWasteChartType, number?: number) => ILegendsProps
>;

export const legendsItem = {
  bar: (color, label, value, percentage, units): ILegendsProps => ({
    type: ELegendsType.Bar,
    label,
    value: formatFloatNumber(value) ?? '',
    units: units ?? 'kg',
    percentage: formatPercentage(percentage) ?? '0',
    color,
  }),
  circle: (color, label, value, percentage, units, number): ILegendsProps => ({
    type: ELegendsType.Circle,
    label,
    value: formatFloatNumber(value) ?? '',
    units: units ?? 'kg',
    percentage: formatPercentage(percentage) ?? '0',
    number: (number ?? 0) + 1,
    color,
  }),
  box: (color, label, value, percentage, units): ILegendsProps => ({
    type: ELegendsType.Box,
    label,
    value: formatFloatNumber(value) ?? '',
    units: units ?? 'kg',
    percentage: formatPercentage(percentage) ?? '0',
    color,
  }),
} as const satisfies Record<
  string,
  (
    color: string,
    label: string,
    value: number,
    percentage: string,
    units?: string,
    number?: number,
  ) => ILegendsProps
>;

export default { stack, text, legends };
