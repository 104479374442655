import React from 'react';
import { IContentProps } from './content.props';
import { HStack, Image, VStack } from '@chakra-ui/react';
import styles from './content.style';
import { NewText } from '@rekosistem/web-components';
import { Button } from '../Button';
import { DOWNLOAD } from 'app/pages/Dashboard/constants';
import { images } from 'assets/images';
import { isEmptyString } from 'utils/check-string';

export const Content: React.FC<IContentProps> = props => {
  return (
    <VStack {...styles.stack.container} {...props?.style}>
      <HStack {...styles.stack.topContainer} {...props?.topStyle}>
        <NewText {...styles.text.title} text={props?.title} />
        {props?.headerRightChildren ? (
          props?.headerRightChildren
        ) : props?.onPressDownload ? (
          <Button
            id="download"
            onClick={props?.onPressDownload}
            {...styles.button.download}
          >
            {DOWNLOAD}
            <Image {...styles.image.download} />
          </Button>
        ) : !isEmptyString(props?.rightTitle) && props?.rightTitle ? (
          <HStack {...styles.stack.topRightContainer}>
            <NewText {...styles.text.rightTitle} text={props?.rightTitle} />
          </HStack>
        ) : null}
      </HStack>
      {props?.isEmpty || !props?.children ? (
        <VStack {...styles.stack.emptyContainer}>
          {props?.emptyIconChildren ?? (
            <Image
              {...styles.image.empty}
              src={props?.emptyIconImage ?? images.emptyDefaultIcon}
            />
          )}
          <VStack {...styles.stack.emptyTextContainer}>
            <NewText
              {...styles.text.emptyTitle}
              text={props?.emptyTitle ?? styles.text.emptyTitle.text}
            />
            <NewText
              {...styles.text.emptySubTitle}
              text={props?.emptySubTitle}
            />
          </VStack>
        </VStack>
      ) : (
        props?.children
      )}
      {props?.isHaveBottomContainer ? (
        <HStack {...styles.stack.bottomContainer}>
          <NewText
            {...styles.text.bottomText}
            text={props?.bottomText ?? styles.text.bottomText.text}
          />
        </HStack>
      ) : null}
    </VStack>
  );
};
