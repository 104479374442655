import { ImageProps, StackProps } from '@chakra-ui/react';
import {
  color,
  ETextType,
  ITextProps,
  radius,
  spacingScale,
} from '@rekosistem/web-components';

const stack = {
  emptyContainer: {
    p: spacingScale['3xL'],
    gap: spacingScale['3xL'],
    justifyContent: 'center',
    alignItems: 'center',
    minH: '400px',
    width: '100%',
  },
  emptyTextContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacingScale['2xS'],
  },
} as const satisfies Record<string, StackProps>;

const text = {
  emptyTitle: {
    text: 'No data available yet.',
    type: ETextType.MParagraphBold,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
  },
  emptySubTitle: {
    text: '-',
    type: ETextType.MParagraphRegular,
    fontSize: '16px',
    lineHeight: '24px',
    color: color.text.secondary,
    textAlign: 'center',
  },
} as const satisfies Record<string, ITextProps>;

const image = {
  empty: {
    borderRadius: radius.full,
    width: '96px',
    height: '96px',
  },
} as const satisfies Record<string, ImageProps>;

export default { stack, text, image };
