import React from 'react';
import {
  CategoryScale,
  Chart as ChartJS,
  defaults,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { NotFound } from '../NotFound';
import {
  DATA_NOT_FOUND_DESC,
  DATA_NOT_FOUND_TITLE,
} from '../../pages/Dashboard/constants';
import {
  IMultiLineChartProps,
  MultiLineDataType,
} from './multi-line-chart.props';
import styles from './multi-line-chart.style';
import { sortDatetMultipleLineChart } from 'utils/epr-dashboard-helper';
import { color } from '@rekosistem/web-components';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

defaults.font.family = 'Nunito';

export const MultiLineChart: React.FC<IMultiLineChartProps> = props => {
  const labels = sortDatetMultipleLineChart(props?.lines);

  const data: MultiLineDataType = {
    labels,
    datasets: props.lines.map(line => ({
      label: line.lineLabel,
      data: labels.map(label => {
        // Find the weight for the current label (date) in the line data
        const dateIndex = line.dates.indexOf(label);
        return dateIndex !== -1 ? line.weights[dateIndex] : null; // Use null for missing dates
      }),
      borderColor: line.color,
      backgroundColor: color.white,
      fill: false,
      spanGaps: true, // Enable line spanning for missing values
    })),
  };

  return (
    <div>
      {props.lines.length && labels.length ? (
        <div id={props.id ?? ''} className="mb-4" style={props?.chartStyle}>
          <Line data={data} options={styles.options(props)} />
        </div>
      ) : (
        <NotFound title={DATA_NOT_FOUND_TITLE} desc={DATA_NOT_FOUND_DESC} />
      )}
    </div>
  );
};
