import { Image, VStack } from '@chakra-ui/react';
import React from 'react';
import { IEmptyStateProps } from './empty-state.props';
import { NewText } from '@rekosistem/web-components';
import { images } from 'assets/images';
import styles from './empty-state.style';

export const EmptyState: React.FC<IEmptyStateProps> = props => {
  return (
    <VStack {...styles.stack.emptyContainer}>
      <Image
        {...styles.image.empty}
        src={props?.icon ?? images.emptyDefaultIcon}
      />
      <VStack {...styles.stack.emptyTextContainer}>
        <NewText
          {...styles.text.emptyTitle}
          text={props?.title ?? styles.text.emptyTitle.text}
        />
        <NewText {...styles.text.emptySubTitle} text={props?.subTitle} />
      </VStack>
    </VStack>
  );
};
