import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

export interface IVerticalBarChartProps {
  label: string;
  xParam: any[];
  xTitle: string;
  yParam: any[];
  yTitle: string;
  color: any[];
  units?: string;
  xTitleColor?: string;
  yTitleColor?: string;
  xParamColor?: string;
  yParamColor?: string;
  gridColor?: string;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

defaults.font.family = 'Montserrat';

export const VerticalBarChart = (props: IVerticalBarChartProps) => {
  const optionsBar = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return ' ' + context.parsed.y + ' ' + props.units;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: props.xTitle,
          color: props?.xTitleColor ?? '#313131',
          font: {
            weight: 'bold',
          },
        },
        ticks: {
          color: props?.xParamColor ?? undefined,
        },
        grid: {
          color: props?.gridColor ?? undefined,
        },
      },
      y: {
        title: {
          display: true,
          text: props.yTitle,
          color: props?.yTitleColor ?? '#313131',
          font: {
            weight: 'bold',
          },
        },
        ticks: {
          color: props?.yParamColor ?? undefined,
        },
        grid: {
          color: props?.gridColor ?? undefined,
        },
      },
    },
  };

  const data = {
    labels: props.yParam,
    datasets: [
      {
        label: props.label,
        data: props.xParam,
        backgroundColor: props.color,
        borderWidth: 0,
        borderRadius: 6,
      },
    ],
  };

  return (
    <>
      <Bar options={optionsBar} data={data} />
    </>
  );
};
